const URI = "ec2-44-201-177-87.compute-1.amazonaws.com"
const propertyBaseUrl =
  // process.env.NODE_ENV === "production"
  //   ?
  //   "api/v1/property"
  //   :
    "https://"+URI+":3001/property"
    // `http://localhot:3001/property`;

const userBaseUrl =
  // process.env.NODE_ENV === "production"
  //   ?
  //   "api/v1/user"
  //   :
    "https://"+URI+":3001/user";

export const apiRoutes = {
  property: {
    getAllProperties: () => `${propertyBaseUrl}`,
    getPropertyById: (id) => `${propertyBaseUrl}/${id}`,
    createPropertyRoute: () => `${propertyBaseUrl}/create-property`,
    editPropertyRoute: () => `${propertyBaseUrl}/edit-property`,
    deletePropertyRoute: (id) => `${propertyBaseUrl}/delete-property/${id}`,
    uploadImagesRoute: () => `${propertyBaseUrl}/images`
    
  },
  user: {
    createUserRoute: () => `${userBaseUrl}/create-user`,
    loginUserRoute: () => `${userBaseUrl}/login-user`,
    getUserProperties: (id) => `${userBaseUrl}/${id}/properties`,
    getUserBookingsRoute: () => `${userBaseUrl}/bookings`,
    bookPropertyRoute: () => `${propertyBaseUrl}/book-property`,
    deleteBookingRoute: () => `${userBaseUrl}/delete-booking`,
    getUserBookings: () => `${userBaseUrl}/get-user-bookings`,
    editUserRoute: (id) => `${userBaseUrl}/edit-user/${id}`,
    deleteUserRoute: (id) => `${userBaseUrl}/delete-user/${id}`,
  },
};
