import React from "react";
import HeaderLaout from "../../components/header/HeaderLayout";
import Feed from "../../components/layouts/Feed";

const Main = () => {
  return (
    <>
      <HeaderLaout />
      <Feed />
    </>
  );
};

export default Main;
